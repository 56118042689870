<template>
	<div class="flex justify-center">
	<div class="loading">
		<span></span>
		<span></span>
		<span></span>
		<span></span>
		<span></span>
		<span></span>
	</div>
</div>
</template>

<style lang="scss" scoped>
	.loading {
	--speed-of-animation: 0.9s;
	--gap: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	gap: 6px;
	height: 100px;
	}

	.loading span {
	width: 4px;
	height: 50px;
	background: theme('colors.red');
	animation: scale var(--speed-of-animation) ease-in-out infinite;
	}

	.loading span:nth-child(2) {
	background: theme('colors.gold');
	animation-delay: -0.8s;
	}

	.loading span:nth-child(3) {
	background: theme('colors.cyan');
	animation-delay: -0.7s;
	}

	.loading span:nth-child(4) {
	background: theme('colors.magenta');
	animation-delay: -0.6s;
	}

	.loading span:nth-child(5) {
	background: theme('colors.lilac');
	animation-delay: -0.5s;
	}
	.loading span:nth-child(6) {
	background: theme('colors.green');
	animation-delay: -0.4s;
	}

	@keyframes scale {
	0%, 40%, 100% {
		transform: scaleY(0.05);
	}

	20% {
		transform: scaleY(1);
	}
	}

</style>